<template>
  <main class="bg-gray-200">
    <section class="min-vh-100 d-flex section-image overlay-soft-dark">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-12 d-flex align-items-center justify-content-center mt-8"
          >
            <div
              class="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500"
            >
              <div class="text-center text-md-center mb-5 mt-md-0">
                <h1 class="mb-0 h3">CHART-IMG.COM</h1>
              </div>

              <div class="d-grid mb-2">
                <button class="btn btn-primary" v-on:click="signInWithGoogle">
                  <span class="me-3"><span class="fab fa-google"></span></span
                  >Sign-in with Google
                </button>
              </div>
              <hr />

              <div
                class="d-flex justify-content-center align-items-center mt-4"
              >
                <span class="fw-normal text-center">
                  By using our site you agree to the <br />
                  <a href="/terms" target="_terms"><u>Terms of Service</u></a>
                  and
                  <a href="/privacy" target="_privacy"><u>Privacy Policy</u></a
                  >.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import {
  auth,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
} from '@/firebase'

const provider = new GoogleAuthProvider()

export default {
  computed: {
    ...mapGetters(['auth']),
  },
  methods: {
    signInWithGoogle() {
      if (this.isMobile()) {
        signInWithRedirect(auth, provider)
      } else {
        signInWithPopup(auth, provider)
          .then(() => {
            this.$router.push({ name: 'subscription' })
          })
          .catch((error) => {
            switch (error.code) {
              case 'auth/wrong-password':
              case 'auth/user-not-found':
                this.$toast.error('Login Failed.')
                break
              case 'auth/popup-closed-by-user':
                break
              default:
                this.$toast.error(error.message)
            }
          })
      }
    },
    msgHidden() {
      this.msg.main.hidden = true
      this.msg.email.hidden = true
      this.msg.password.hidden = true
    },
    btnDisabled() {
      this.btn.submit.disabled = true
    },
    btnEnabled() {
      this.btn.submit.disabled = false
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) // prettier-ignore
    },
    ...mapMutations(['setUser']),
  },
  data() {
    return {
      email: '',
      password: '',
      msg: {
        main: {
          text: '',
          hidden: true,
        },
        email: {
          text: '',
          hidden: true,
        },
        password: {
          text: '',
          hidden: true,
        },
      },
      btn: {
        submit: {
          disabled: false,
        },
      },
    }
  },
}
</script>
